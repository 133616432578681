import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import app_data from "@/constants/app";

export default {
  handleError(error = null, message = "Något problem uppstod", position = "top-right", timeout = 5000) {
    if (message != "")
      this.$toasted.show(message, {
        type: "error",
        theme: "outline",
        position: position,
        duration: timeout,
        action: {
          text: "X",
          class: "font-sans",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });

    // if(process.env.VUE_APP_ENV === 'development' && error != null) console.error(error);
    console.error(error);

    if (error != null) {
      this.axios.post(`${process.env.VUE_APP_SERVER_URL}/frontend_error`, {
        message: error.message || "",
        stack: error.stack || "",
        url: this.$route.fullPath,
        username: this.$store.state.user ? this.$store.state.user.username : "",
        role: this.$store.state.user ? this.$store.state.user.role : "",
      });
    }
  },

  handleSuccess(message = "Åtgärd slutförts", position = "top-right", timeout = 5000) {
    this.$toasted.show(message, {
      type: "success",
      theme: "outline",
      position: position,
      duration: timeout,
      action: {
        text: "X",
        class: "font-sans",
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      },
    });
  },

  setPageTitle(title = "", routeName) {
    if (this.$route.name == routeName) this.$store.commit("SET_PAGE_TITLE", title);
  },

  formatDate(date) {
    return this.moment(date)
      .tz("Europe/Stockholm")
      .format("ddd, YYYY-MM-DD, HH:mm");
  },

  excelExport(data, sheetName = "", fileName = "") {
    if (data.length) {
      let filtered = data.filter((el) => Object.keys(el).length);
      var columns = XLSX.utils.json_to_sheet(filtered);

      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, columns, sheetName);

      let this_date = new Date().toISOString().slice(0, 10);
      this_date = this_date.replace(/[-]/gi, "");

      XLSX.writeFile(wb, `${this_date}_${fileName}.xlsx`);
    } else {
      this.handleError(null, "Ingen data att exportera", "top-right", 3000);
    }
  },

  async getUser() {
    try {
      let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/user`);
      this.$store.commit("SET_USER", response.data.data);
      this.$store.commit("SET_ADDONS", response.data.addons);
    } catch (error) {
      this.handleError(error);
    }
  },

  async refreshToken() {
    try {
      let data = { refresh_token: this.$store.state.refreshToken, username: this.$store.state.user.username };

      let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/refresh_token`, data);
      let { accessToken } = response.data;

      this.$store.commit("SET_TOKEN", accessToken);
      // todo: also change token in axios header

      return accessToken;
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.$store.dispatch("logout");
        this.$router.push("/").catch(() => {});
      }

      return null;
    }
  },

  generateGoogleMapActionDiv() {
    let controlDiv = document.createElement("div");
    controlDiv.className += "lg:hidden";

    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#1A8DE9";
    controlUI.style.border = "1px solid #1A8DE9";
    controlUI.style.borderRadius = "2px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "10px";
    controlUI.style.marginBottom = "15px";
    controlUI.style.marginRight = "8px";
    controlUI.style.textAlign = "center";
    controlUI.title = "Click to open menu";
    controlDiv.appendChild(controlUI);

    const controlText = document.createElement("div");
    controlText.style.color = "#fff";
    controlText.style.fontFamily = "'Source Sans Pro', sans-serif";
    controlText.style.fontSize = "18px";
    controlText.style.fontWeight = "600";
    controlText.style.lineHeight = "38px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Alarm";
    controlUI.appendChild(controlText);

    return controlDiv;
  },

  zoomButton(direction) {
    let canvasContainer = this.$refs.canvasContainer;
    let containerWidth = canvasContainer.offsetWidth;

    let stage = this.$refs.stage.getStage();
    var scaleBy = 1.11;

    let oldScale = stage.scaleX();
    var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    let center = {
      x: stage.width() / 2,
      y: stage.height() / 2,
    };

    let relatedTo = {
      x: (center.x - stage.x()) / oldScale,
      y: (center.y - stage.y()) / oldScale,
    };

    this.configStage.scaleX = newScale;
    this.configStage.scaleY = newScale;

    let newPos = {
      x: center.x - relatedTo.x * newScale,
      y: center.y - relatedTo.y * newScale,
    };
    stage.position(newPos);

    if (containerWidth < 500) {
      this.configLabelGroup.scaleX = 0.4 / newScale;
      this.configLabelGroup.scaleY = 0.4 / newScale;
    } else {
      this.configLabelGroup.scaleX = 1 / newScale;
      this.configLabelGroup.scaleY = 1 / newScale;
    }
  },

  zoomWheel(e) {
    e.evt.preventDefault();

    let canvasContainer = this.$refs.canvasContainer;
    let containerWidth = canvasContainer.offsetWidth;

    let stage = this.$refs.stage.getStage();
    var scaleBy = 1.01;

    var oldScale = stage.scaleX();
    var pointer = stage.getPointerPosition();

    var mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    let direction = e.evt.deltaY > 0 ? 1 : -1;

    if (e.evt.ctrlKey) {
      direction = -direction;
    }

    var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    this.configStage.scaleX = newScale;
    this.configStage.scaleY = newScale;

    var newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };
    stage.position(newPos);

    if (containerWidth < 500) {
      this.configLabelGroup.scaleX = 0.4 / newScale;
      this.configLabelGroup.scaleY = 0.4 / newScale;
    } else {
      this.configLabelGroup.scaleX = 1 / newScale;
      this.configLabelGroup.scaleY = 1 / newScale;
    }
  },

  zoomTouch(e) {
    e.evt.preventDefault();

    let canvasContainer = this.$refs.canvasContainer;
    let containerWidth = canvasContainer.offsetWidth;

    var stage = this.$refs.stage.getStage();

    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];

    if (touch1 && touch2) {
      if (stage.isDragging()) {
        stage.stopDrag();
      }

      var p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };
      var p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      if (!this.lastCenter) {
        this.lastCenter = this.getCenter(p1, p2);
        return;
      }
      var newCenter = this.getCenter(p1, p2);

      var dist = this.getDistance(p1, p2);

      if (!this.lastDist) {
        this.lastDist = dist;
      }

      var pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX(),
      };

      var scale = stage.scaleX() * (dist / this.lastDist);

      this.configStage.scaleX = scale;
      this.configStage.scaleY = scale;

      var dx = newCenter.x - this.lastCenter.x;
      var dy = newCenter.y - this.lastCenter.y;

      var newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      };

      stage.position(newPos);

      this.lastDist = dist;
      this.lastCenter = newCenter;

      if (containerWidth < 500) {
        this.configLabelGroup.scaleX = 0.4 / scale;
        this.configLabelGroup.scaleY = 0.4 / scale;
      } else {
        this.configLabelGroup.scaleX = 1 / scale;
        this.configLabelGroup.scaleY = 1 / scale;
      }
    }
  },

  zoomTouchEnd(e) {
    e.evt.preventDefault();
    this.lastDist = 0;
    this.lastCenter = null;
  },

  zoomReset() {
    let canvasContainer = this.$refs.canvasContainer;
    let containerWidth = canvasContainer.offsetWidth;

    let scale = containerWidth / this.imageSize.width;
    this.configStage.scaleX = scale;
    this.configStage.scaleY = scale;

    if (containerWidth < 500) {
      this.configLabelGroup.scaleX = 0.4 / scale;
      this.configLabelGroup.scaleY = 0.4 / scale;
    } else {
      this.configLabelGroup.scaleX = 1 / scale;
      this.configLabelGroup.scaleY = 1 / scale;
    }

    var stage = this.$refs.stage.getStage();
    stage.position({ x: 0, y: 0 });
  },

  getCenter(p1, p2) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  },

  getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  },

  calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  },

  getCellStyle(highlight, newVal, oldVal) {
    let style = {};
    if (highlight && oldVal != newVal) style = { fillColor: "#ffd589" };
    return style;
  },

  async generateAlarmPlanPDF({ alarm, customerName, sellers, oldAlarm, highlight, showAlarmNumber }) {
    let type = "seller",
      reseller_logo,
      company_name = app_data.contact_info.company_name,
      company_number = app_data.contact_info.company_number,
      company_address = app_data.contact_info.company_address,
      email = app_data.contact_info.email,
      phone = app_data.contact_info.phone,
      contact_person = app_data.contact_info.contact_person,
      contact_email = app_data.contact_info.contact_email,
      contact_phone = app_data.contact_info.contact_phone;

    let seller = _.find(sellers, { _id: alarm.seller });
    if (seller && (seller.type == "reseller" || seller.type == "alarm_center")) {
      type = "reseller";
      reseller_logo = seller.logo;
      company_name = seller.name;
      company_number = seller.company_number;
      company_address = `${seller.address}\n${seller.zip_code} ${seller.city}`;
      email = seller.email;
      phone = seller.phone;
      contact_person = seller.contact_person;
      contact_email = seller.contact_email;
      contact_phone = seller.contact_phone;
    }

    let y = 0;
    let c = 1;

    var doc = new jsPDF();

    // header
    var logo = new Image();
    logo.crossOrigin = "anonymous";

    if (type == "reseller") logo.src = reseller_logo + `?time=${new Date().getTime()}`;
    else logo.src = require("@/assets/images/securdia_pdf_logo.png");

    await logo.decode();

    let maxWidth = 300;
    let maxHeight = 80;
    let { width, height } = this.calculateAspectRatioFit(logo.width, logo.height, maxWidth, maxHeight);

    width = width * 0.264583; // to mm
    height = height * 0.264583; // to mm

    doc.addImage(logo, "PNG", 15, 5, width, height);

    doc.setFontSize(12);
    doc.text(this.$t("alarm_pdf.title"), 195, 14, { align: "right" });

    // supplier
    this.generateHeading(doc, y + 25, this.$t("alarm_pdf.supplier"), c);
    c++;
    doc.setFontSize(11);

    doc.autoTable({
      startY: 50,
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.name"), this.$t("alarm_pdf.org_number"), this.$t("alarm_pdf.address")]],
      body: [[company_name, company_number, company_address]],
    });
    doc.autoTable({
      theme: "grid",
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.contact_company"), this.$t("alarm_pdf.e_mail"), this.$t("alarm_pdf.phone")]],
      body: [
        [company_name, email, phone],
        [contact_person, contact_email, contact_phone],
      ],
    });

    // alarm plan
    y = doc.lastAutoTable.finalY;
    this.generateHeading(doc, y, this.$t("alarm_pdf.data_alarm_plan"), c);
    c++;
    y = y + 25;

    // customer name
    doc.autoTable({
      startY: y,
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.customer")]],
      body: [[customerName]],
    });

    y = doc.lastAutoTable.finalY + 7;

    // check which column height is greator
    let colMaxY = y;

    // left column
    doc.autoTable({
      startY: y,
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.business_name")]],
      body: [[{ content: alarm.alarm_unit_name, styles: this.getCellStyle(highlight, alarm.alarm_unit_name, oldAlarm.alarm_unit_name) }]],
      margin: { right: 107 },
    });
    doc.autoTable({
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.alerm_name")]],
      body: [[{ content: alarm.unit_name, styles: this.getCellStyle(highlight, alarm.unit_name, oldAlarm.unit_name) }]],
      margin: { right: 107 },
    });
    doc.autoTable({
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.number_of_users")]],
      body: [[{ content: alarm.user, styles: this.getCellStyle(highlight, alarm.user, oldAlarm.user) }]],
      styles: { overflow: "hidden" },
      margin: { right: 107 },
    });

    colMaxY = doc.lastAutoTable.finalY;

    // right column
    doc.autoTable({
      startY: y,
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.alarm_type")]],
      body: [[{ content: alarm.selected_alarm_sort, styles: this.getCellStyle(highlight, alarm.selected_alarm_sort, oldAlarm.selected_alarm_sort) }]],
      styles: { overflow: "hidden" },
      margin: { left: 107 },
    });
    doc.autoTable({
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.risk_profile")]],
      body: [[{ content: alarm.risk_profile, styles: this.getCellStyle(highlight, alarm.risk_profile, oldAlarm.risk_profile) }]],
      styles: { overflow: "hidden" },
      margin: { left: 107 },
    });
    doc.autoTable({
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.based_on_address")]],
      body: [[{ content: alarm.from_address.city, styles: this.getCellStyle(highlight, alarm.from_address.city, oldAlarm.from_address.city) }]],
      margin: { left: 107 },
    });

    if (doc.lastAutoTable.finalY > colMaxY) colMaxY = doc.lastAutoTable.finalY;
    // end column layout

    y = colMaxY;

    doc.autoTable({
      startY: y + 7,
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.work_assignment")]],
      body: [[{ content: alarm.business_description, styles: this.getCellStyle(highlight, alarm.business_description, oldAlarm.business_description) }]],
    });

    y = doc.lastAutoTable.finalY;
    if (y > 240) {
      doc.addPage();
      y = 10;
    }

    // personal alarm
    if (alarm.selected_alarm_sort == "Personligt") {
      this.generateHeading(doc, y, this.$t("alarm_pdf.case_of_personal_alarm"), c);
      c++;

      doc.autoTable({
        startY: y + 27,
        headStyles: { fillColor: [62, 178, 222] },
        head: [[this.$t("alarm_pdf.person_name"), this.$t("alarm_pdf.characteristics"), this.$t("alarm_pdf.threat_image")]],
        body: [
          [
            { content: alarm.personal_alarm.reporting, styles: this.getCellStyle(highlight, alarm.personal_alarm.reporting, oldAlarm.personal_alarm.reporting) },
            { content: alarm.personal_alarm.risk_profile, styles: this.getCellStyle(highlight, alarm.personal_alarm.risk_profile, oldAlarm.personal_alarm.risk_profile) },
            { content: alarm.personal_alarm.name, styles: this.getCellStyle(highlight, alarm.personal_alarm.name, oldAlarm.personal_alarm.name) },
          ],
        ],
      });

      y = doc.lastAutoTable.finalY;
      if (y > 240) {
        doc.addPage();
        y = 10;
      }
    }

    // alarm unit
    this.generateHeading(doc, y, this.$t("alarm_pdf.alarm_unit"), c);
    c++;

    let alarmUnitCol = [this.$t("alarm_pdf.telematics_number"), this.$t("alarm_pdf.imei"), this.$t("alarm_pdf.device_type")];
    let alarmUnitBody = [
      { content: alarm.phone, styles: this.getCellStyle(highlight, alarm.phone, oldAlarm.phone) },
      { content: alarm.imei_number, styles: this.getCellStyle(highlight, alarm.imei_number, oldAlarm.imei_number) },
      { content: alarm.type, styles: this.getCellStyle(highlight, alarm.type, oldAlarm.type) },
    ];

    if (showAlarmNumber) {
      alarmUnitCol.push(this.$t("alarm_pdf.alarm_number"));
      alarmUnitBody.push({ content: alarm.alarm_number, styles: this.getCellStyle(highlight, alarm.alarm_number, oldAlarm.alarm_number) });
    }

    doc.autoTable({
      startY: y + 27,
      headStyles: { fillColor: [62, 178, 222] },
      head: [alarmUnitCol],
      body: [alarmUnitBody],
    });

    // contact persons
    y = doc.lastAutoTable.finalY;
    y = doc.lastAutoTable.finalY;
    if (y > 240) {
      doc.addPage();
      y = 10;
    }

    this.generateHeading(doc, y, this.$t("alarm_pdf.contact_person"), c);
    c++;

    let contact_person_desc = this.$t("alarm_pdf.contact_person_details");
    doc.setFontSize(9);
    doc.setFont(undefined, "normal");
    doc.text(contact_person_desc, 15, y + 27);

    let contact_persons = [];
    for (let i = 0; i < alarm.contact_persons.length; i++) {
      let el = alarm.contact_persons[i];
      contact_persons.push([
        { content: el.name, styles: this.getCellStyle(highlight, el.name, oldAlarm.contact_persons[i].name) },
        { content: el.function, styles: this.getCellStyle(highlight, el.function, oldAlarm.contact_persons[i].function) },
        { content: el.phone, styles: this.getCellStyle(highlight, el.phone, oldAlarm.contact_persons[i].phone) },
        { content: el.weekday, styles: this.getCellStyle(highlight, el.weekday, oldAlarm.contact_persons[i].weekday) },
        { content: el.saturday, styles: this.getCellStyle(highlight, el.saturday, oldAlarm.contact_persons[i].saturday) },
        { content: el.sunday, styles: this.getCellStyle(highlight, el.sunday, oldAlarm.contact_persons[i].sunday) },
      ]);
    }
    doc.autoTable({
      startY: y + 43,
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.name"), this.$t("alarm_pdf.function"), this.$t("alarm_pdf.number"), this.$t("alarm_pdf.everyday"), this.$t("alarm_pdf.saturday"), this.$t("alarm_pdf.sun_weekend")]],
      body: contact_persons,
    });

    // external contractor
    y = doc.lastAutoTable.finalY;
    this.generateHeading(doc, y, this.$t("alarm_pdf.external_agent"), c);
    c++;

    doc.autoTable({
      startY: y + 27,
      headStyles: { fillColor: [62, 178, 222] },
      head: [[this.$t("alarm_pdf.name"), this.$t("alarm_pdf.contact_number"), this.$t("alarm_pdf.id_perpetrator")]],
      body: [
        [
          { content: alarm.external_contractor.name, styles: this.getCellStyle(highlight, alarm.external_contractor.name, oldAlarm.external_contractor.name) },
          { content: alarm.external_contractor.phone, styles: this.getCellStyle(highlight, alarm.external_contractor.phone, oldAlarm.external_contractor.phone) },
          { content: alarm.external_contractor.object_id, styles: this.getCellStyle(highlight, alarm.external_contractor.object_id, oldAlarm.external_contractor.object_id) },
        ],
      ],
    });

    // password
    y = doc.lastAutoTable.finalY;
    this.generateHeading(doc, y, this.$t("alarm_pdf.authorization_code"), c);
    c++;

    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    if (highlight && alarm.alarm_password != oldAlarm.alarm_password) doc.setTextColor("orange");
    doc.text(alarm.alarm_password, 17, y + 30);
    doc.setTextColor(0, 0, 0);

    // personal password
    y = y + 33;
    this.generateHeading(doc, y, this.$t("alarm_pdf.personal_password"), c);
    c++;

    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    if (highlight && alarm.personal_password != oldAlarm.personal_password) doc.setTextColor("orange");
    doc.text(alarm.personal_password, 17, y + 30);
    doc.setTextColor(0, 0, 0);

    // alarm profile
    y = y + 33;
    if (y > 210) {
      doc.addPage();
      y = 10;
    }

    this.generateHeading(doc, y, this.$t("alarm_pdf.select_alarm_profile"), c);
    c++;

    if (highlight && alarm.risk_profile != oldAlarm.risk_profile) doc.setTextColor("orange");
    doc.text(alarm.risk_profile, 17, y + 30);
    doc.setTextColor(0, 0, 0);

    let risk_title = this.$t("alarm_pdf.risk_title");
    let risk_desc = this.$t("alarm_pdf.risk_desc");

    if (alarm.risk_profile == "Nödlarm") {
      risk_title = this.$t("alarm_pdf.risk_title_2");
      risk_desc = this.$t("alarm_pdf.risk_desc_2");
    } else if (alarm.risk_profile == "Överfallslarm (ej återkallningsbart)") {
      risk_title = this.$t("alarm_pdf.risk_title_3");
      risk_desc = this.$t("alarm_pdf.risk_desc_3");
    }

    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(this.$t("alarm_pdf.primary_input"), 17, y + 38);

    doc.setFontSize(9);
    doc.setFont(undefined, "normal");
    doc.text(risk_title, 17, y + 45);

    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(this.$t("alarm_pdf.action_in_alarm"), 17, y + 62);

    doc.setFontSize(9);
    doc.setFont(undefined, "normal");
    doc.text(risk_desc, 18, y + 70);

    return doc;
  },

  generateHeading(doc, y, title, c) {
    doc.setDrawColor(0, 0, 0);
    doc.setTextColor(0, 0, 0);

    doc.line(15, y + 10, 195, y + 10);

    doc.setFontSize(13);
    doc.setFont(undefined, "bold");
    doc.text(`${c}. ${title}`, 17, y + 17);

    doc.line(15, y + 21, 195, y + 21);
  },

  generateAgreementPDF(agreement) {
    var doc = new jsPDF();

    // padding common
    let titlePadding = { top: 2, right: 2, bottom: 1, left: 2 };
    let contentPadding = { top: 0, right: 2, bottom: 2, left: 2 };
    let yZeroPadding = { top: 0, right: 2, bottom: 0, left: 2 };

    // logo
    var logo = new Image();
    logo.src = require("@/assets/images/securdia_pdf_logo.png");
    doc.addImage(logo, "PNG", 15, 5, 39.86, 11.29);

    // agreement number
    let agreement_head = [this.$t("alarm_pdf.agreement_number"), this.$t("alarm_pdf.e_invoice")];
    let agreement_text = [agreement.agreement_number, agreement.e_bill];
    if (agreement.e_bill == "") {
      agreement_head = [this.$t("alarm_pdf.agreement_number")];
      agreement_text = [agreement.agreement_number];
    }

    doc.autoTable({
      startY: 25,
      head: [agreement_head],
      body: [agreement_text],
      theme: "plain",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      margin: { right: 120 },
      headStyles: {
        halign: "right",
        fontSize: 8,
        cellPadding: titlePadding,
      },
      columnStyles: {
        0: { halign: "right", fontSize: 7, cellPadding: contentPadding },
        1: { halign: "right", fontSize: 7, cellPadding: contentPadding },
      },
      didDrawCell: function(data) {
        if (data.column.index === 0) {
          data.doc.setDrawColor(0, 0, 0);
          data.doc.line(data.cell.x + data.cell.width, data.cell.y, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
        }
      },
    });

    // agreement seller, tenants
    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 5,
      showHead: "never",
      body: [[this.$t("alarm_pdf.lessee"), this.$t("alarm_pdf.lessor")]],
      theme: "plain",
      columnStyles: {
        0: { fontSize: 10, fontStyle: "bold", cellPadding: { top: 0, right: 0, bottom: 0, left: 0 } },
        1: { fontSize: 10, fontStyle: "bold", cellPadding: { top: 0, right: 0, bottom: 0, left: 0 } },
      },
    });

    let address_arr = [
      [
        { content: this.$t("alarm_pdf.name"), colSpan: 2, styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
        { content: this.$t("alarm_pdf.name"), colSpan: 2, styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
      ],
      [
        { content: agreement.customer_name, colSpan: 2, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
        { content: agreement.dealer_name, colSpan: 2, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
      ],
      [
        { content: "Organisationsnummer", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
        { content: "Telefon, äv riktnr", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
        { content: "Organisationsnummer", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
        { content: "Telefon, äv riktnr", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
      ],
      [
        { content: agreement.company_number, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
        { content: agreement.customer_phone, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
        { content: agreement.dealer_company_number, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
        { content: agreement.dealer_phone, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
      ],
      [
        { content: "Fakturaadress", colSpan: 2, styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
        { content: "Adress", colSpan: 2, styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
      ],
      [
        { content: agreement.billing_adress, colSpan: 2, styles: { fontSize: 7, halign: "left", cellPadding: yZeroPadding } },
        { content: agreement.dealer_address, colSpan: 2, styles: { fontSize: 7, halign: "left", cellPadding: yZeroPadding } },
      ],
      [
        { content: `${agreement.billing_zip_code}, ${agreement.billing_city}`, colSpan: 2, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
        { content: `${agreement.dealer_zip_code}, ${agreement.dealer_city}`, colSpan: 2, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
      ],
      [
        { content: "Kontaktperson", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
        { content: "Ref", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
        { content: "Säljare", colSpan: 2, styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
      ],
      [
        { content: agreement.customer_contact, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
        { content: agreement.customer_reference, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
        { content: this.getSellerName(agreement.seller), colSpan: 2, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
      ],
    ];

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 1,
      showHead: "never",
      theme: "plain",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      body: address_arr,
      didDrawCell: function(data) {
        if ((data.cell.colSpan === 2 && data.column.index === 0) || data.cell.colSpan === 1) {
          data.doc.setDrawColor(0, 0, 0);
          data.doc.line(data.cell.x + data.cell.width, data.cell.y, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
        }

        if (data.row.index == 1 || data.row.index == 3 || data.row.index == 6) {
          data.doc.setDrawColor(0, 0, 0);
          data.doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
        }
      },
    });

    // unit specifications
    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 3,
      theme: "plain",
      head: [["Objektspecifikation, tillverkningsår, extra utrustning mm"]],
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      headStyles: {
        halign: "left",
        fontSize: 8,
        cellPadding: titlePadding,
      },
      body: [[{ content: agreement.units_specification, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } }]],
    });

    // contract amount
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(14, doc.autoTable.previous.finalY + 7, "Hyresobjekt");

    doc.setFontSize(6);
    doc.setFont(undefined, "bold");
    doc.text(14, doc.autoTable.previous.finalY + 11, "Villkor ");
    doc.setFont(undefined, "normal");
    doc.text(doc.getTextWidth("Villkor ") + 15, doc.autoTable.previous.finalY + 11, "(samtliga belopp är angivna exklusive mervärdesskatt)");

    let amount_arr = [
      [
        { content: "Avtalsstart", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
        { content: "Avtalsperiod", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
        { content: "Betalningsvillkor", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
      ],
      [
        { content: this.moment(agreement.contract_start).format("YYYY-MM-DD"), styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
        { content: `${agreement.contract_period} mån`, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
        { content: agreement.payment_terms, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
      ],
      [
        { content: "Uppläggningsavgift", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
        { content: "Hyreskostnad/mån", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } },
        { content: "", styles: { fontSize: 8, fontStyle: "bold", halign: "left", cellPadding: titlePadding } }, // blank cell to fix table padding
      ],
      [
        { content: agreement.setup_cost, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
        { content: `${agreement.rental_month_cost} SEK`, styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } },
        { content: "", styles: { fontSize: 7, halign: "left", cellPadding: contentPadding } }, // blank cell to fix table padding
      ],
    ];

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 13,
      showHead: "never",
      theme: "plain",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      body: amount_arr,
    });

    // special terms
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(14, doc.autoTable.previous.finalY + 7, "Särskilda villkor");

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 9,
      showHead: "never",
      theme: "plain",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      body: [[{ content: agreement.special_terms, styles: { fontSize: 7, fontStyle: "normal", halign: "left", cellPadding: 2 } }]],
    });

    doc.setFontSize(7);
    doc.setFont(undefined, "normal");
    let splitText = doc.splitTextToSize('Detta avtal har upprättats i två likalydande exemplar, av vilka parterna tagit var sitt. Avtalet är bindande för Hyrestagaren genom dennes underskrift på kontraktet och för Securdia AB när avtalet undertecknats av denna. Hyrestagaren bekräftar härmed att de tagit del av och godkänt samtliga villkor enligt denna sida samt enligt "ALLMÄNNA VILLKOR LEASING" vilka bifogas detta avtal. Observera att hyra av lös egendom bland annat innebär att Hyrestagaren väljer Hyresobjekt och Leverantör samt står riskerna för inköp, funktion och användning.', 180);
    doc.text(14, doc.autoTable.previous.finalY + 5, splitText);

    // signature
    doc.setFontSize(10);
    doc.setFont(undefined, "bold");
    doc.text(14, doc.autoTable.previous.finalY + 21, "Underskrifter - Firmateckning");

    let sign_arr = [
      [
        { content: "Ort och datum", styles: { fontSize: 8, fontStyle: "bold", halign: "left" } },
        { content: "Ort och datum", styles: { fontSize: 8, fontStyle: "bold", halign: "left" } },
      ],
      [
        { content: "Underskrift Hyresgivare Securdia AB", styles: { fontSize: 8, fontStyle: "bold", halign: "left" } },
        { content: "Underskrift Hyrestagare", styles: { fontSize: 8, fontStyle: "bold", halign: "left" } },
      ],
      [
        { content: "Namnförtydligande", styles: { fontSize: 8, fontStyle: "bold", halign: "left" } },
        { content: "Namnförtydligande och befattning", styles: { fontSize: 8, fontStyle: "bold", halign: "left" } },
      ],
    ];

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 25,
      theme: "grid",
      showHead: "never",
      styles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
        cellPadding: { top: 2, right: 2, bottom: 6, left: 2 },
      },
      body: sign_arr,
    });

    // footer
    doc.setFontSize(8);
    doc.setFont(undefined, "normal");
    doc.setTextColor(0, 51, 102);
    doc.text("Securdia AB, Huvudkontor Stockholm, org. Nr. 559270-2087 Ekbacksvägen 22, 3tr 168 69 Bromma", 100, 289, null, null, "center");
    doc.text("Ekonomi@securdia.se	Tel: +46(0) 08-520 276 42", 100, 292, null, null, "center");

    // back
    doc.addPage();

    let content_fsize = 5.65;
    let text_padding = { top: 1, right: 1, bottom: 1, left: 1 };

    let terms = [
      [
        {
          content: "ALLMÄNNA VILLKOR LEASING",
          styles: { fontSize: content_fsize, fontStyle: "bold", halign: "left", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "För leasingavtal mellan å ena sidan Securdia AB (”SC”) och å andra sidan leasetagaren (”LT”), gemensamt ”Parterna” gäller följande allmänna villkor om inte annat skriftligen avtalats mellan parterna.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "1. Mottagande, undersökning, reklamation och leveransgodkännande LT ska på egen bekostnad vidtaga erforderliga åtgärder för att kunna mottaga leaseobjektet när det av SC ställs till LT:s förfogande. Risken för leaseobjektet övergår till  LT när leaseobjektet överlämnas till LT eller, om leaseobjektet ska transporteras till LT, när leaseobjektet överlämnas till den transportör som har åtagit sig transporten från SC. När risken har övergått till LT, är LT skyldig att betala för leaseobjektet även om det förstörs, kommer bort, försämras eller minskar i värde genom en händelse som inte beror på SC. LT ska vid leverans av leaseobjektet på egen bekostnad omedelbart undersöka detta. Undersökningen ska utföras noggrant och enligt normal branschpraxis. Som bekräftelse på att leaseobjektet erhållits och godkänts, ska LT utan dröjsmål underteckna och tillställa SC detta avtal. Om leaseobjektet är felaktigt ska LT genast skriftligen reklamera till SC. LT ska även anses ha godkänt leveransen av objektet, med samma verkan som genom att tillställa SC ett påskrivet avtal, genom att ta objektet i bruk eller genom att erlägga leasingkostnad eller annan betalning i enlighet med detta avtal, jämte om LT inte senast 10 dagar efter mottagen leverans av leaseobjektet skriftligen reklamerar till SC.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "2. Fel, brist, dröjsmål m.m. LT ska utan dröjsmål underrätta SC om fel eller skada på, samt stöld av, leaseobjektet under leasetiden. SC ska efter sådan underrättelse meddela LT vilka åtgärder LT ska vidta. Vid garanti- eller försäkringsfall upprättar SC skadeanmälan till försäkringsbolaget. Vid behov ska leaseobjektet besiktas gemensamt av SC och LT, för att säkerställa leaseobjektets goda skick. Samtliga skador på leaseobjektet som inte förelåg vid leveranstillfället ska ersättas av LT. Skadat eller förlorat leaseobjekt som inte ersätts genom leaseobjektets försäkring debiteras LT till nyanskaffningsvärde.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "3. Försenad eller felaktig leverans Om SC inte tillhandahåller leaseobjektet i funktionsdugligt skick vid avtalad tidpunkt har LT rätt att häva leasingavtalet eller begära sådan nedsättning av leasingkostnaden som svarar mot felet eller dröjsmålet. SC är dock inte skyldig att ersätta LT för annan skada (direkt skada, indirekt skada eller följdskada) som LT eller tredje man åsamkats till följd av felet eller dröjsmålet. Hävning, skadestånd eller nedsättning av leasingkostnaden får inte göras gällande, när SC erbjuder sig att avhjälpa fel på leaseobjektet eller ersätta leaseobjektet med annan godtagbar ersättningsprodukt, och rättelse sker inom skälig tid och utan kostnad för LT. Sådan påföljd får inte heller göras gällande om inte LT genast efter det att LT märkt, eller bort märka, felet meddelar SC härom (reklamation). Har LT inte undersökt leaseobjektet vid leverans, får LT inte såsom fel åberopa vad LT borde ha märkt vid undersökningen, om inte SC har handlat i strid mot tro och heder.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "4. Start av Leasetid, Leveransperiod m.m. Leasetiden börjar den första dagen i nästkommande kalendermånad efter leverans (enligt punkten Mottagande ovan). Med kalendermånad avses månader med början 1:a januari, 1:a februari, 1:a mars och 1:a april osv. Från datum för fullgjord leverans till leasetidens första dag debiteras LT en avgift om en trehundrasextiodels (1/360) leasekostnad per dag. Avgiften debiteras på LT:s första faktura och förfaller till betalning samtidigt med leasingkostnaden. Eventuell sådan avgift jämte leasingkostnad för tolv månader (”Leasingkostnaden”), ska erläggas och vara SC tillhanda senast trettio dagar efter leasetidens första dag. Leasingkostnaden erlägges därefter, om ej annat skriftligen överenskommits, årsvis i förskott och ska vara SC tillhanda senast sista bankdagen  före varje betalningsperiods början.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "5. Övriga kostnader SC:s samtliga kostnader, såsom kostnader för transport, installation, försäkring, tull, skatt, kursdifferenser och dylikt som avser leaseobjektet och som inte inkluderats i leasingkostnaden, ska vid anfordran betalas av LT.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "6. Indexering och allmänna betalningsvillkor Om arbetskostnadsindex förändras äger SC rätt att justera framtida avgifter proportionellt med förändringen av detta index. Tillämpning sker enligt SCB:s senast publicerade AKI, B–S exkl. O, på årsvärdesnivå (för närvarande maj månad varje år). Samtliga priser gäller exklusive mervärdeskatt. LT betalar leasingkostnaden i förskott. Om inte annat överenskommits är betalningstiden 30 dagar netto. Dröjsmålsränta debiteras med riksbankens fastställda, vid varje tid gällande referensränta med tillägg av åtta procentenheter, vartill – i förekommande fall – kommer lagstadgad påminnelse- och inkassoavgift. Fakturering sker en gång per avtalsår, avslutade leasingavtal faktureras snarast. SC förbehåller sig rätten att debitera faktureringsavgift samt uppläggningsavgift för nya konton.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "7. Mervärdesskatt, dröjsmålsränta, kravavgifter m.m. Utöver leasingkostnad ska LT erlägga mervärdesskatt samt andra skatter och avgifter som sammanhänger med leaseobjektet eller leaseförhållandet. Mervärdesskatt erlägges samtidigt med leasingkostnaden, övriga skatter och avgifter vid anfordran. Utöver leasingkostnad utgår särskilda avgifter som vid var tid tillämpas av SC, såsom för bevakning och indrivning av fordran hos LT, häri särskilt inbegripet inkassoavgifter och avgifter för betalningspåminnelser samt för kostnader i samband med återtagande av leaseobjektet. Vid dröjsmål med erläggande av leasingkostnad eller annan betalning enligt detta avtal, ska LT erlägga dröjsmålsränta på förfallet belopp med för närvarande 2% per månad.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "8. Förfogande över leaseobjektet och användning m.m. Leaseobjektet är SC:s egendom och LT förvärvar ej på grund av leasingavtalet någon äganderätt till objektet. SC upplåter leaseobjektet till LT på de villkor som anges i detta avtal. LT äger inte rätt att vidta åtgärd som äventyrar SC:s äganderätt till leaseobjektet. LT får till exempel inte: a) införliva leaseobjektet med sin eller annans fasta eller lösa egendom; b) vidtaga ändringar i eller påbyggnader av leaseobjektet utan medgivande från SC; c) pantsätta eller överlåta leaseobjektet; d) överlåta detta avtal på annan, uthyra leaseobjektet till annan eller genom avtal med annan förfoga över rätt till leaseobjektet eller detta avtal; e) förflytta leaseobjektet från den angivna adressen enligt detta avtal. Skulle under leasetiden rättslig åtgärd, såsom utmätning, kvarstad, e.d. vidtagas beträffande leaseobjektet, är LT skyldig att för utmätningsmannen uppvisa sitt exemplar av leasingavtalet samt att upplysa utmätningsmannen om SC:s rätt till leaseobjektet. LT är dessutom skyldig att omedelbart skriftligen underrätta SC om den av utmätningsmannen vidtagna åtgärden. LT ansvarar för att leaseobjektet endast brukas av därtill behörig och utbildad personal.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "9. Besiktning SC äger under leasetiden rätt att besiktiga leaseobjektet. LT ska lämna SC tillträde till den lokal där leaseobjektet finns samt lämna erforderligt biträde vid genomförandet av sådan besiktning.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "10. Vård av leaseobjektet och service LT ska väl vårda och underhålla leaseobjektet, inklusive att följa användarinstruktioner, så att leaseobjektet är i gott funktionellt skick. Vid leasetidens upphörande ska leaseobjektet ej vara i sämre skick än vad som är normalt med hänsyn till ålder och normal användning. Skulle leaseobjektet på grund av förslitning, vanvård eller annat skäl bli obrukbart före leasetidens utgång är LT trots detta skyldig att fullgöra sina förpliktelser enligt detta Avtal. Det åligger LT att ombesörja och bekosta eventuellt erforderlig reparation av leaseobjektet förutom i de fall då fråga är om garanti- eller försäkringsfall. Under tid då leaseobjektet är under reparation ska full leasingkostnad utgå. LT får inte ändra produktmärkning, identifikation eller skriftliga användarinstruktioner avseende leaseobjektet. LT får inte, med undantag för vård av leaseobjektet, utan SC:s skriftliga godkännande låta reparera eller förändra leaseobjektet. Skador och defekter i leaseobjektet som under leasetiden uppstår på grund av LT:s brukande av leaseobjektet eller annars på grund av LT eller tredje man, ska bekostas av LT. Reparationer av fel i leaseobjektet som förelegat vid avtalstidens början bekostas av SC. SC tillhandahåller service på leaseobjektet vilken ingår i leasingkostnaden. Service tillhandahålls normalt på plats hos leasetagaren. SC är inte skyldigt att ställa ersättningsobjekt till LT:s förfogande under reparations- eller servicetid.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "11. Försäkring SC tecknar de försäkringar som SC finner är erforderliga för leaseobjektet. Om ej annat skriftligen överenskommits utgår avgift för sådan försäkring utöver leasingkostnaden men faktureras samtidigt med den. LT överlåter härmed till SC all rätt till ersättning vid försäkringsfall. Samtliga utfallande försäkringsbelopp ska tillfalla SC. SC är därutöver berättigad att av LT erhålla självriskbelopp som avräknas vid reglering av försäkringsfall. Vid försäkringsfall ska LT utan dröjsmål anmäla skada på eller förlust av leaseobjektet, eller del därav, till försäkringsbolaget samt skicka kopia av anmälan till SC. Vid skadeanmälan ska kopia av detta avtal inges till försäkringsbolaget. På LT:s begäran kan skriftlig information om de fullständiga villkoren för tecknad försäkring tillhandahållas.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "12. Skada på leaseobjekt m.m. LT svarar från leverans av leaseobjektet t o m den dag leaseobjektet mottagits av SC eller dennes representant i samband med avtalets upphörande, oberoende av vållande, för förlust av eller skada på leaseobjektet. Således är LT betalningsskyldig och ansvarig för skada, inklusive självrisk, som inte täcks av försäkring. a) Om leaseobjektet helt eller delvis förloras, förslits eller förstörs, ska LT omedelbart underrätta SC därom. Härvid har SC rätt att begära särskild säkerhet av LT för återstående betalningar enligt detta avtal;. b) Skada på eller förlust av leaseobjektet befriar inte LT från dennes förpliktelser enligt detta avtal. Detta gäller även om LT inte kan bruka leaseobjektet; c) Vid skada på leaseobjektet där SC anser att reparation bör ske ska utfallande försäkringsersättning användas till betalning av reparationskostnad. LT svarar för de reparations- och övriga kostnader som inte täcks av försäkringen. Om leaseobjektet, eller del därav, helt förloras eller skadas så att reparation enligt SC:s bedömning ej kan ske, ska LT fortsätta att erlägga leasingkostnaden till SC fram till dess SC erhållit ersättning från försäkringsbolaget, alternativt leasingavtalet upphör. Om skadan och ersättningen enligt ovan avser hela leaseobjektet ska leasingavtalet upphöra att gälla när SC erhållit full betalning enligt denna punkt, om skadan och ersättningen avser del av leaseobjektet ska leasingavtalet gälla oförändrat avseende övriga delar.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "13. Skada orsakad av leaseobjektet Det åligger LT att, oberoende av vållande, svara för all sak-, person-, och/eller förmögenhetsskada som leaseobjektet eller dess användning förorsakar, samt hålla SC skadeslös och ansvarsfri med anledning av eventuella krav på grund av dylika skador.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "14. Myndighets föreskrift m.m. LT förbinder sig att under leasetiden inte bruka leaseobjektet utan att dessförinnan förvissa sig om att detsamma i vad mån avser utrustning, skyddsanordningar och dylikt uppfyller alla gällande författningar och av myndighet uppställda krav. SC är inte ansvarig om hinder i utnyttjandet av leaseobjektet föreligger eller uppstår genom föreskrift i författning och/eller myndighets beslut. Sådant hinder kan ej utgöra grund för ersättningskrav från LT gentemot SC och ej heller åberopas som skäl till befrielse helt eller delvis från förpliktelser som åvilar LT.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "15. Leasetid, förlängning och återlämnande Leasetiden är angiven på avtalets framsida. Om LT inte skriftligen till SC senast 90 dagar före avtalad leasetidens utgång eller, om förlängning skett, före förlängningsperiodens utgång, meddelat SC att återlämnande ska ske, förlängs leasetiden varje gång sådant meddelande uteblir, automatiskt med 12 månader, nedan kallad förlängningsperioden. Leasingkostnaden under förlängningsperioden äger SC rätt att justera till den vid den tidpunkten gällande prisnivå som gäller för motsvarande leaseobjekt. För förlängningsperioden gäller samma betalningsperiodicitet och samma förfallodagar som för den ursprungliga leasetiden. Under förlängningsperioden fortsätter i övrigt villkoren i detta avtal att gälla oförändrade. I annat fall ska LT, senast inom 30 dagar efter leasetidens utgång, på egen bekostnad och risk återlämna leaseobjektet, inklusive samtliga tillbehör såsom laddsladd, bärtillbehör m.m., till av SC anvisad plats i Sverige. Om leaseobjektet vid återlämnandet är i sämre skick än vad som är normalt med hänsyn till ålder och normal användning äger SC att på LT:s bekostnad åtgärda sådana brister i leaseobjektets skick. Om återlämnande av leaseobjektet inkl samtliga tillbehör inte sker i rätt tid, debiteras LT till nyanskaffningsvärde.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "16. SC:s rätt till uppsägning av avtalet, skadestånd SC äger rätt att med omedelbar verkan uppsäga avtalet och genast på LT:s bekostnad återtaga leaseobjektet om: a) LT dröjer med erläggande av leasingkostnad, förfallna skatter, avgifter eller andra kostnader enligt avtalet med mer än 30 dagar; b) LT inställer betalningarna, ansöker om företagsrekonstruktion eller om sådan beslutas beträffande LT, försätts i konkurs, begär eller inleder ackordsförhandling, träder i likvidation eller eljest enligt SC:s bedömning kan befaras vara på obestånd; c) LT vanvårdar leaseobjektet eller förvägrar SC besiktning av leaseobjektet; d) det enligt SC:s uppfattning finns skälig anledning att anta att LT inte kommer att fullgöra sina förpliktelser mot SC enligt leasingavtalet eller annan förpliktelse mot SC; e) LT i övrigt åsidosätter någon bestämmelse i leasingavtalet; f) LT inte svarar på SC:s frågor eller på annat sätt inte bidrar till att SC löpande uppnår kundkännedom. Om uppsägningsgrund föreligger enligt ovan äger SC även rätt att säga upp annat avtal med LT. För den händelse att avtalet sägs upp av SC av anledning som anges ovan, ska SC äga rätt till, förutom leasingkostnader och andra belopp som är förfallna, även skadestånd som, om inte annan skada kan visas, ska uppgå minst till avgifter för resterande leasetid, allt diskonterat till nuvärde vid uppsägningstidpunkten. Nuvärdesdiskontering ska ske till en räntesats motsvarande gällande STIBOR 30 dagar. Därtill kommer samtliga kostnader för återtagande av leaseobjektet. Skadeståndet ska minskas med intäkt, exkl. moms, som SC efter avdrag för försäljnings-, rekonditionerings- och övriga kostnader erhåller vid försäljning av leaseobjektet, om sådan är möjlig enligt SC:s gottfinnande.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "17. Överlåtelse Avtalet och därmed följande rättigheter och skyldigheter får inte, helt eller delvis, överlåtas av part utan att den andra parten har lämnat sitt skriftliga medgivande till sådan överlåtelse.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "18. Ändring och tillägg Ändringar och tillägg till detta avtal ska ske skriftligen och undertecknas av båda parter.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "19. Meddelanden m.m. LT ska omedelbart underrätta SC om adress- och/eller namnändring. Meddelande rörande detta avtal som SC sänder till LT, ska anses ha nått LT senast på fjärde dagen efter avsändandet, om brevet sänts till i avtalet angiven adress eller annan av SC känd adress. Meddelande som sänts med e-post ska anses ha nått LT omedelbart.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "20. Ansvarsbegränsning SC är inte ansvarig gentemot LT för skada som beror på lagbud, myndighetsåtgärd, krigshändelse, strejk, blockad, bojkott, lockout eller annan liknande omständighet utanför SC:s kontroll. Detta förbehåll gäller vare sig SC själv är part i eller föremål för eller vidtar sådan åtgärd. Förhindras SC , till följd av omständighet som anges ovan, att fullgöra åtagande gentemot LT, ska SC vara berättigad att uppskjuta fullgörandet härav till dess hindret upphört. SC är heller inte skyldig att ersätta skada som uppkommer, om SC varit normalt aktsam. SC är inte i något fall skyldig att utge ersättning för indirekt skada eller följdskada (utebliven vinst, produktionsbortfall m.m.).",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "21. Lagval och Forum Svensk lag ska tillämpas på detta avtal. Tvister som uppstår i anledning av avtalet ska slutligt avgöras genom skiljedomsförfarande administrerat av SCC Skiljedomsinstitut (SCC). Regler för Förenklat Skiljeförfarande ska tillämpas om inte SCC med beaktande av målets svårighetsgrad, tvisteföremålets värde och övriga omständigheter bestämmer att Skiljedomsregler ska tillämpas. I sistnämnda fall ska SCC också bestämma om skiljenämnden ska bestå av en eller tre skiljedomare. Skiljeförfarandets säte ska vara Stockholm. Språket för förfarandet ska vara svenska. Skiljeförfarande som påkallats med hänvisning till denna skiljeklausul omfattas av sekretess. Sekretessen omfattar all information som framkommer under förfarandet liksom beslut eller skiljedom som meddelas i anledning av förfarandet.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: "22. Information om behandling av personuppgifter För LT:s personuppgifter är SC personuppgiftsansvarig. SC behandlar LT:s personuppgifter i enlighet med vid var tid gällande integritetspolicy som finns tillgänglig på SC:s webbsida. SC redovisar informationen om behandlingen av personuppgifter för LT i samband med att LT ingår avtalet varigenom LT godkänner SC:s personuppgiftsbehandling. LT är införstådd med att SC för att leva upp till de rättsliga krav som ställs beträffande dokumentation för bl.a. förhindrande av penningtvätt, måste behandla LT:s personuppgifter.",
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
      [
        {
          content: '23. Programvara och licensrättigheter I leaseobjektet ingår programvara/licensrättighet till SecurCloud (nedan "Programvaran"). Genom detta avtal godkänner och bekräftar SC att programvaran får nyttjas av LT i enlighet med villkoren i detta avtal. SC ansvarar inte i något fall för skada med anledning av LT:s nyttjande av programvaran (förlust av data, direkt skada, indirekt skada eller följdskada).',
          styles: { fontSize: content_fsize, fontStyle: "normal", halign: "justify", cellPadding: text_padding },
        },
      ],
    ];

    doc.autoTable({
      startY: 5,
      showHead: "never",
      theme: "plain",
      body: terms,
    });

    return doc;
  },

  checkIOS() {
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
    this.isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    return this.isIOS;
  },
  getMapLink(mapType, gps_pos) {
    if (mapType === "google") {
      return `https://www.google.com/maps?saddr=My+Location&daddr=${gps_pos.lat},${gps_pos.lng}`;
    }

    if (mapType === "apple") {
      return `http://maps.apple.com/?saddr=Current+Location&daddr=${gps_pos.lat},${gps_pos.lng}`;
    }
  },
};
